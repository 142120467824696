import produce from 'immer'

const initialState = {
  title: 'Plataforma',
  favicon: null,
  logo: null,
  logoWhite: null,
  color: null,
  loading: false,
  error: null,
}

export default (state = initialState, { type, payload }) =>
  produce(state, draft => {
    const newDraft = draft

    switch (type) {
      case '@whiteLabel/REQUEST': {
        newDraft.error = null
        newDraft.loading = true
        break
      }
      case '@whiteLabel/SUCCESS': {
        newDraft.favicon = payload.data.favicon
        newDraft.logo = payload.data.logo
        newDraft.logoWhite = payload.data.logo_white
        newDraft.color = payload.data.color
        newDraft.title = payload.data.title
        newDraft.loading = false
        break
      }
      case '@whiteLabel/FAILURE': {
        newDraft.error = payload.error
        newDraft.loading = false
        break
      }
      default:
    }
  })
