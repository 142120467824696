/* eslint-disable react/prop-types */
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { Alert } from 'antd'
import PropTypes from 'prop-types'

import logMessage from 'utils/log'

function ErrorFallback({ error }) {
  const intl = useIntl()
  const user = useSelector(state => state.user?.profile)

  logMessage('error_fallback', 'ERROR', String(error), user?.usu_login)

  return (
    <Alert
      message={intl.formatMessage({ id: 'global.error.messages.defaultError' })}
      type="error"
      showIcon
    />
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.shape({}).isRequired,
}

export default ErrorFallback
