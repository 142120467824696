/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import Icon from '@ant-design/icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Drawer, Col, Typography } from 'antd'
import getValidRoute from 'helpers/getValidRoute'
import { toggleMenuMobile } from 'store/modules/siderMenu/actions'

// import useIsMobileWidth from 'hooks/useIsMobileWidth'
import useLogger from 'hooks/useLogger'

import history from 'services/history'

import packageJson from '../../../../../../package.json'
import Logo from '../Logo'
import ButtonConfig from './ButtonConfig'
import { links, configLinks } from './links'
import { scrollToTop } from './scrollToTop'
import { NavLink, SubMenuTitle, StyledMenu } from './styles'
import useAccessControlMenu from './useAccessControlMenu'

// Need to move a this import after migration to react
library.add(fal)

const { SubMenu } = StyledMenu

const Menu = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user?.profile)
  // const unitSelected = useSelector(state => state.unitSelected?.data)
  const isOpenMenuMobile = useSelector(
    state => state.siderMenu.isOpenMenuMobile
  )
  const isCollapsedSider = useSelector(
    state => state.siderMenu.isCollapsedSider
  )
  const [mainRoutes, setMainRoutes] = useState(links)
  const [configRoutes, setConfigRoutes] = useState(configLinks)
  const { pathname } = useLocation()
  // const [isMobile] = useIsMobileWidth()
  const accessControl = useSelector(state => state.accessControl.data)

  const [selectedKeys, setSelectedKeys] = useState([pathname])
  const [menu, setMenu] = useState(
    pathname.includes('settings') ? 'config' : 'main'
  )
  const [lastRouteConfig, setLastRouteConfig] = useState('/settings/tags')
  const [lastRouteMain, setLastRouteMain] = useState(
    '/analysis/analysis-process'
  )
  const [setLog] = useLogger()

  // const [loading, setLoading] = useState(false)
  // const [dashboardList, setDashboardList] = useState([])

  const { newMainRoutes, newConfigRoutes } = useAccessControlMenu({
    hasAccess: user?.hasAccess,
    accessControl,
    mainRoutes,
    configRoutes,
  })

  // const intl = useIntl()

  // // Remove after move to API
  useEffect(() => {
    if (user?.cliCode !== 'JBS') {
      setMainRoutes(prevState =>
        prevState.filter(link => link.to.pathname !== '/scale')
      )
    }
    if (user?.cliCode === 'OLF') {
      setMainRoutes(
        prevState =>
          prevState
            .filter(link => link.to.pathname !== '/root-cause')
            .filter(link => link.to.pathname !== '/root-cause/effects-page')
            .filter(
              link => link.to.pathname !== '/root-cause/generate-analysis'
            )
            .filter(link => link.to.pathname !== '/control-loop-management')
            .filter(
              link =>
                link.to.pathname !== '/control-loop-management/performance'
            )
            .filter(
              link => link.to.pathname !== '/control-loop-management/overview'
            )
            .filter(link => link.to.pathname !== '/integrated-maintenance')
            .filter(
              link =>
                link.to.pathname !==
                '/integrated-maintenance/service-order-panel'
            )
            .filter(
              link =>
                link.to.pathname !==
                '/integrated-maintenance/service-order-generator'
            )
            .filter(
              link => link.to.pathname !== '/integrated-maintenance/report'
            )
            .filter(
              link => link.to.pathname !== '/integrated-maintenance/telemetry'
            )
        // .filter(
        //   link =>
        //     link.to.pathname !==
        //     '/integrated-maintenance/maintenance-overview'
        // )
        // .filter(
        //   link =>
        //     link.to.pathname !==
        //     '/integrated-maintenance/maintenance-overview/history'
        // )
        // .filter(
        //   link =>
        //     link.to.pathname !==
        //     '/integrated-maintenance/maintenance-overview/graphs'
        // )
      )
      setConfigRoutes(prevState =>
        prevState
          .filter(link => link.to.pathname !== '/settings/root-cause')
          .filter(
            link => link.to.pathname !== '/settings/control-loop-management'
          )
          .filter(
            link => link.to.pathname !== '/settings/integrated-maintenance'
          )
          .filter(
            link =>
              link.to.pathname !==
              '/settings/operational-structure/elements-tree'
          )
          .filter(
            link =>
              link.to.pathname !==
              '/settings/integrated-maintenance/materials-services'
          )
          .filter(
            link =>
              link.to.pathname !== '/settings/integrated-maintenance/modalities'
          )
          .filter(
            link =>
              link.to.pathname !== '/settings/integrated-maintenance/frequency'
          )
          .filter(
            link =>
              link.to.pathname !== '/settings/integrated-maintenance/status'
          )
          .filter(
            link =>
              link.to.pathname !==
              '/settings/integrated-maintenance/maintenance-activity'
          )
          .filter(
            link =>
              link.to.pathname !==
              '/settings/integrated-maintenance/service-order-types'
          )
          .filter(
            link =>
              link.to.pathname !==
              '/settings/integrated-maintenance/labor-rules'
          )
          .filter(
            link =>
              link.to.pathname !== '/settings/integrated-maintenance/services'
          )
          .filter(
            link =>
              link.to.pathname !==
              '/settings/integrated-maintenance/inspection-routes'
          )
          .filter(
            link =>
              link.to.pathname !==
              '/settings/integrated-maintenance/report-registration'
          )
      )
    }
  }, [user])

  useEffect(() => {
    setLastRouteMain(getValidRoute(newMainRoutes))
  }, [newMainRoutes])

  useEffect(() => {
    setLastRouteConfig(getValidRoute(newConfigRoutes))
  }, [newConfigRoutes])

  function getIsMobile() {
    let mobileAndTabletCheck = false

    // eslint-disable-next-line func-names
    ;(function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        mobileAndTabletCheck = true
    })(navigator.userAgent || navigator.vendor || window.opera)

    return mobileAndTabletCheck
  }

  function onChange(selectedContext) {
    setSelectedKeys(selectedContext.selectedKeys)
    scrollToTop(500)
    if (menu === 'main') {
      setLastRouteMain(selectedContext.item.props.pathname)
    } else {
      setLastRouteConfig(selectedContext.item.props.pathname)
    }
    if (getIsMobile()) {
      dispatch(toggleMenuMobile())
    }

    const currentItem =
      menu === 'main'
        ? mainRoutes.find(
            route => route.to.pathname === selectedContext.item.props.pathname
          ) ||
          mainRoutes
            .map(route =>
              route.children?.find(
                child =>
                  child.to.pathname === selectedContext.item.props.pathname
              )
            )
            .find(item => item)
        : configRoutes.find(
            route => route.to.pathname === selectedContext.item.props.pathname
          ) ||
          configRoutes
            .map(route =>
              route.children?.find(
                child =>
                  child.to.pathname === selectedContext.item.props.pathname
              )
            )
            .find(item => item)

    if (currentItem) {
      setLog('log.actions.access', currentItem.label)
    }
  }

  function onChangeMenu() {
    scrollToTop(500)
    if (menu === 'main') {
      setMenu('config')
      history.push(lastRouteConfig)
    } else {
      setMenu('main')
      history.push(lastRouteMain)
    }
  }

  function renderMenuItem(menuItem) {
    if (menuItem?.children?.length) {
      return (
        <SubMenu
          key={menuItem.id}
          pathname={menuItem.to.pathname}
          title={
            <>
              {menuItem.icon && (
                <Icon
                  component={() => (
                    <FontAwesomeIcon icon={['fal', menuItem.icon]} />
                  )}
                />
              )}

              <SubMenuTitle className="ant-subtitle-menu-custom">
                <FormattedMessage id={menuItem.label} />
              </SubMenuTitle>
            </>
          }
        >
          {menuItem.children.map(renderMenuItem)}
        </SubMenu>
      )
    }

    return (
      <StyledMenu.Item key={menuItem.id} pathname={menuItem.to.pathname}>
        {menuItem.icon && (
          <Icon
            component={() => <FontAwesomeIcon icon={['fal', menuItem.icon]} />}
          />
        )}
        <NavLink className="ant-subtitle-menu-custom">
          <FormattedMessage id={menuItem.label} />
        </NavLink>
        <Link to={menuItem.to.pathname} />
      </StyledMenu.Item>
    )
  }

  const renderedLogo = <Logo collapse={isCollapsedSider} />

  const renderedButtonConfig = (user?.hasAccess ||
    (newMainRoutes.length > 0 && newConfigRoutes.length > 0)) && (
    <Row type="flex" justify="center">
      <ButtonConfig menu={menu} onChangeMenu={onChangeMenu} />
    </Row>
  )

  const renderedVersionSection = !getIsMobile() && (
    <Row type="flex" justify="space-around">
      <Col span={12} style={{ textAlign: 'center' }}>
        <Typography.Text strong>{packageJson.version}</Typography.Text>
      </Col>
    </Row>
  )

  const renderedMenu =
    menu === 'main' && newMainRoutes.length
      ? newMainRoutes.map(renderMenuItem)
      : newConfigRoutes.map(renderMenuItem)

  const renderComponentsMenu = () => (
    <>
      {renderedLogo}
      <StyledMenu
        selectedKeys={selectedKeys}
        onSelect={onChange}
        mode={getIsMobile() ? 'inline' : 'vertical'}
      >
        {renderedMenu}
      </StyledMenu>
      {renderedButtonConfig}
      {renderedVersionSection}
    </>
  )

  return (
    <>
      {getIsMobile() ? (
        <Drawer
          placement="left"
          closable={false}
          width="220px"
          bodyStyle={{ padding: 0 }}
          onClose={() => dispatch(toggleMenuMobile())}
          visible={isOpenMenuMobile}
        >
          {renderComponentsMenu()}
        </Drawer>
      ) : (
        renderComponentsMenu()
      )}
    </>
  )
}

export default Menu
