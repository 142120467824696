import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { message, Button } from 'antd'
import moment from 'moment'

function Notification() {
  const contract = useSelector(state => state.contract)
  const history = useHistory()
  const intl = useIntl()

  const openExpiredScreen = () => history.push('/expired')

  useEffect(() => {
    if (contract.expiredDate) {
      const dayToExpire = moment(contract.expiredDate).diff(moment(), 'days')

      if (dayToExpire > 0 && dayToExpire < 30) {
        message.warning({
          content: (
            <>
              {intl.formatMessage(
                {
                  id: 'expired.timeToExpire',
                },
                { count: dayToExpire }
              )}
              <Button type="link" size="small" onClick={openExpiredScreen}>
                {intl.formatMessage({
                  id: 'expired.renewTextButton',
                })}
              </Button>
            </>
          ),
          key: 'contract-expiring',
          duration: 0,
        })
      } else if (alert) {
        message.destroy('contract-expiring')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract.isExpired, contract.expiredDate, contract.loading, intl])

  return null
}

export default Notification
