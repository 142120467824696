import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import {
  Avatar,
  Button,
  Spin,
  Checkbox,
  Comment,
  Descriptions,
  Input,
  message,
  Tabs,
  Typography,
} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import AntModal from 'components/AntModal'
import AvatarList from 'components/AvatarList/AvatarList'
import FromNow from 'components/FromNow'

import axiosClient from 'services/api'

import CommentList from './CommentList'
import Editor from './Editor'

const { TabPane } = Tabs
const { Text } = Typography
const CheckboxGroup = Checkbox.Group
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

function ModalAlert({
  visible,
  currentAlert,
  toggleModal,
  items,
  setItemViewed,
  acl,
}) {
  const [comments, setComments] = useState([])
  const [users, setUsers] = useState([])
  const [groups, setGroups] = useState([])
  const [notifyUsersList, setNotifyUsersList] = useState([])
  const [alert, setAlert] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [value, setValue] = useState('')
  const [cause, setCause] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingData] = useState(false)
  const [disabledController, setDisabledController] = useState(false)
  const [fade, setFade] = useState(false)

  const intl = useIntl()

  const userProfile = useSelector(state => state.user.profile)

  const getData = async a => {
    setLoading(true)
    try {
      const response = await axiosClient
        .get(`ALE/entities/ALEMOV/${a.id_movalerta}`)
        .then(resp => resp.data)

      const filtered = items.map(item => a.id_mvu === item.id_mvu)
      const newFilter = filtered.indexOf(true)

      setDisabledController(newFilter)
      setAlert(
        response.data.map(item => ({
          ...item,
          id_mvu: a.id_mvu,
        }))[0]
      )
      setUsers(response.data[0].viewedBy)
    } catch (error) {
      console.log(error)
      message.error(
        intl.formatMessage({ id: 'global.error.messages.loadDataError' })
      )
    } finally {
      setLoading(false)
    }
  }

  async function getComments() {
    try {
      if (alert.id_movalerta) {
        const response = await axiosClient
          .get(`ALE/entities/MENSAGEM/${alert.id_movalerta}`)
          .then(resp => resp.data)

        setComments(
          response.data
            .map(comment => ({
              author: `${comment.usu_nome} - ${comment.usu_login}`,
              avatar: comment.usu_foto,
              content: <p>{comment.mensagem}</p>,
              date: moment(comment.data).utc(),
              datetime: (
                <FromNow
                  date={moment(comment.data)}
                  formatDay="dddd HH:mm:ss "
                  formatMonth="DD MMMM HH:mm:ss"
                  formatYear="DD MMMM YYYY HH:mm:ss"
                />
              ),
            }))
            .sort((a, b) => a.date - b.date)
            .reverse()
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (alert.id_movalerta) getComments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledController, alert, users])

  useEffect(() => {
    async function loadGroups() {
      await axiosClient.get(
        `ALE/queries/updateAlertReadByUser/${currentAlert.id_mvu}?userID=${userProfile.id}`
      )
      const response = await axiosClient
        .get(`ALE/entities/ALEGRUPO`)
        .then(resp => resp.data)

      setGroups(response.data)
    }
    loadGroups()
    setInterval(getComments, 1000 * 30)
    getData(currentAlert)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSetCause = async () => {
    await axiosClient.post('ALE/queries/insertCause', {
      id_movalerta: alert.id_movalerta,
      mov_causa: cause,
    })

    setAlert({
      ...alert,
      mov_causa: cause,
    })
    setCause('')
  }

  const handleSubmit = async () => {
    if (!value) {
      return
    }

    setSubmitting(true)

    const response = await axiosClient
      .post('ALE/entities/MENSAGEM', {
        id_usuario: userProfile.id,
        id_movalerta: alert.id_movalerta,
        mensagem: value,
        data: moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      })
      .then(resp => resp.data)

    setSubmitting(false)
    setValue('')
    setComments(prevComments =>
      [
        ...prevComments,
        {
          author: `${userProfile.name} - ${userProfile.usu_login}`,
          avatar: userProfile.photo,
          content: <p>{response.data[0].mensagem}</p>,
          date: moment(response.data[0].data).utc(),
          datetime: (
            <FromNow
              date={moment(response.data[0].data)}
              formatDay="dddd HH:mm:ss "
              formatMonth="DD MMMM HH:mm:ss"
              formatYear="DD MMMM YYYY HH:mm:ss"
            />
          ),
        },
      ]
        .sort((a, b) => a.date - b.date)
        .reverse()
    )
    getComments()
  }

  const handleValueChange = e => {
    setValue(e.target.value)
  }

  const notifyUsers = async () => {
    setLoading(true)

    try {
      await axiosClient.post('ALE/queries/notifyUsers', {
        alertID: alert.id_alerta,
        alertMovID: alert.id_movalerta,
        users: notifyUsersList,
      })

      message.success(intl.formatMessage({ id: 'alert.notificationSent' }))
    } catch (err) {
      console.log(err)
      message.error(
        intl.formatMessage({ id: 'global.error.messages.serverError' })
      )
    } finally {
      setLoading(false)
    }
  }

  const handleNextAlert = async () => {
    setFade(true)
    const filtered = items.map(item => alert.id_mvu === item.id_mvu)
    const newFilter = filtered.indexOf(true)
    const nextAlert = items[newFilter + 1]

    try {
      const response = await axiosClient
        .get(`ALE/entities/ALEMOV/${nextAlert.id_movalerta}`)
        .then(resp => resp.data)

      await axiosClient.get(
        `ALE/queries/updateAlertReadByUser/${nextAlert.id_mvu}?userID=${userProfile.id}`
      )

      const newAlert = response.data.map(item => ({
        ...item,
        id_mvu: nextAlert.id_mvu,
      }))[0]

      getData(newAlert)
      setItemViewed(newAlert)

      setUsers(response.data[0].viewedBy)
      setComments([])
      setDisabledController(newFilter + 1)
    } catch (err) {
      console.log(err)
    } finally {
      setFade(false)
    }
  }

  const prevAlert = async () => {
    setFade(true)
    const filtered = items.map(item => alert.id_mvu === item.id_mvu)
    const newFilter = filtered.indexOf(true)
    const previousAlert = items[newFilter - 1]

    try {
      const response = await axiosClient
        .get(`ALE/entities/ALEMOV/${previousAlert.id_movalerta}`)
        .then(resp => resp.data)

      await axiosClient.get(
        `ALE/queries/updateAlertReadByUser/${previousAlert.id_mvu}?userID=${userProfile.id}`
      )
      const newAlert = response.data.map(item => ({
        ...item,
        id_mvu: previousAlert.id_mvu,
      }))[0]

      setUsers(response.data[0].viewedBy)
      setComments([])
      setDisabledController(newFilter - 1)

      getData(newAlert)
      setItemViewed(newAlert)
    } catch (err) {
      console.log(err)
    } finally {
      setFade(false)
    }
  }

  return (
    <AntModal
      toggleModal
      visibleModal
      width={1000}
      title={intl.formatMessage({ id: 'alert.title' })}
      visible={visible}
      onOk={toggleModal}
      onCancel={() => toggleModal(false)}
      footer={null}
    >
      <div className={fade ? 'fade' : ''}>
        <Spin indicator={antIcon} spinning={loadingData}>
          <Descriptions bordered>
            <Descriptions.Item
              label={intl.formatMessage({ id: 'global.labels.message' })}
              span={3}
            >
              {alert.mov_msg_alerta}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: 'global.labels.date' })}
            >
              {alert.mov_dt_alerta &&
                moment(alert.mov_dt_alerta).format('DD/MM/YYYY HH:mm:ss')}
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: 'alert.seen' })}
              span={3}
            >
              <AvatarList
                list={users.map(user => ({
                  ...user,
                  id: user.id_usuario,
                  title: user.usu_nome,
                  icon: 'user',
                  src: user.usu_foto,
                }))}
                listLimit={5}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={intl.formatMessage({ id: 'alert.cause' })}
              span={3}
            >
              {alert.mov_causa ? (
                alert.mov_causa
              ) : (
                <Input
                  value={cause}
                  disabled={!acl.can('create')}
                  onChange={e => setCause(e.target.value)}
                  addonAfter={
                    acl.can('create') && (
                      <CheckOutlined onClick={handleSetCause} />
                    )
                  }
                />
              )}
            </Descriptions.Item>
          </Descriptions>

          <Tabs defaultActiveKey="1">
            <TabPane tab={intl.formatMessage({ id: 'alert.chat' })} key="1">
              {comments.length > 0 && (
                <CommentList comments={comments} users={users} />
              )}
              {acl.can('create') && (
                <Comment
                  avatar={
                    <Avatar
                      src={userProfile.photo}
                      alt={`${userProfile.name} - ${userProfile.usu_login}`}
                    />
                  }
                  content={
                    <Editor
                      onValueChange={handleValueChange}
                      nextAlert={handleNextAlert}
                      prevAlert={prevAlert}
                      onSubmit={handleSubmit}
                      submitting={submitting}
                      value={value}
                      disabledController={disabledController}
                      items={items}
                      intl={intl}
                    />
                  }
                />
              )}
            </TabPane>
            <TabPane
              tab={intl.formatMessage({ id: 'alert.notificate' })}
              key="2"
            >
              {groups.map(group => (
                <div
                  style={{ marginBottom: '25px' }}
                  key={group.id_grupoalerta}
                >
                  <div style={{ borderBottom: '1px solid #E9E9E9' }}>
                    {group.gru_descri}
                  </div>
                  <br />
                  <CheckboxGroup
                    options={group.users.map(u => ({
                      value: u,
                      label: (
                        <>
                          {u.usu_nome}
                          {' - '}
                          <Text type="warning">{u.usu_login}</Text>
                        </>
                      ),
                    }))}
                    value={notifyUsersList}
                    onChange={list => setNotifyUsersList(list)}
                  />
                </div>
              ))}
              {acl.can('create') && (
                <Button type="primary" onClick={notifyUsers} loading={loading}>
                  {intl.formatMessage({ id: 'alert.sendNotification' })}
                </Button>
              )}

              <Button
                shape="circle"
                icon={<ArrowRightOutlined />}
                style={{ float: 'right' }}
                onClick={handleNextAlert}
                disabled={disabledController === items.length - 1}
              />
              <Button
                shape="circle"
                icon={<ArrowLeftOutlined />}
                style={{ float: 'right' }}
                onClick={prevAlert}
                disabled={disabledController === items.length - items.length}
              />
            </TabPane>
          </Tabs>
        </Spin>
      </div>
    </AntModal>
  )
}

ModalAlert.defaultProps = {
  items: [],
}

ModalAlert.propTypes = {
  acl: PropTypes.func.isRequired,
  currentAlert: PropTypes.shape({
    id_mvu: PropTypes.number,
  }).isRequired,
  items: PropTypes.shape({
    id_movalerta: PropTypes.number,
    id_mvu: PropTypes.number,
    map: PropTypes.func,
    length: PropTypes.number,
  }),
  setItemViewed: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    photo: PropTypes.string,
    usu_login: PropTypes.string,
  }).isRequired,
  visible: PropTypes.bool.isRequired,
}

export default ModalAlert
