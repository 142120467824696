import { useSelector } from 'react-redux'

function useAccessControl(id) {
  const accessControl = useSelector(state => state.accessControl?.data) || {}
  const user = useSelector(state => state.user?.profile) || {}

  function can(action) {
    if (user.hasAccess) {
      return true
    }

    // console.info(`accessControl.${id}.${action}`)
    try {
      return Boolean(accessControl[id][action])
    } catch (error) {
      return false
    }
  }

  function is(role) {
    const currentUserRole = user.hasAccess ? 'admin' : 'user'

    return role === currentUserRole
  }

  return { can, is }
}

export default useAccessControl
