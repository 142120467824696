import React, { useMemo, createContext } from 'react'
import { useLocation } from 'react-router-dom'

import PropTypes from 'prop-types'

import { links } from 'components/Layout/Sidebar/Components/Menu/links'

const RouteContext = createContext({
  currentPage: null,
  redirectTo: null,
})

export const RouteProvider = ({ children }) => {
  const location = useLocation()
  const redirectTo = useMemo(() => {
    const { state } = location

    return state?.from || ''
  }, [location])

  const dataContext = useMemo(() => {
    const currentPage =
      links.find(page => location.pathname === page?.to?.pathname) ||
      links
        .map(link => link.children)
        .flat()
        .find(page => location.pathname === page?.to?.pathname)

    return { currentPage, redirectTo }
  }, [location.pathname, redirectTo])

  return (
    <RouteContext.Provider value={dataContext}>
      {children}
    </RouteContext.Provider>
  )
}

RouteProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default RouteContext
