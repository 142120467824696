import React, { useMemo } from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'

import Highcharts from 'highcharts'
import enUS from 'locale/en_US'
import esES from 'locale/es_ES'
import ptBR from 'locale/pt_BR'
import moment from 'moment'
import PropTypes from 'prop-types'

import flattenObject from 'utils/flattenObject'

/**
 * Use react-intl apply translated terms
 * with FormattedMessage and useIntl
 * more infos: https://formatjs.io/docs/react-intl
 */

const availableMessages = {
  'pt-BR': ptBR,
  'en-US': enUS,
  'es-ES': esES,
  es: esES,
}

const getMessages = localeKey => {
  const messages = availableMessages[localeKey]

  return messages || enUS
}

function getMomentLocale(localeKey) {
  if (localeKey === 'pt-BR' || localeKey === 'pt') {
    return moment.updateLocale('pt-br', {
      months:
        'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split(
          '_'
        ),
      monthsShort: 'Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez'.split('_'),
      weekdays:
        'Domingo_Segunda-feira_Terça-feira_Quarta-feira_Quinta-feira_Sexta-feira_Sábado'.split(
          '_'
        ),
      weekdaysShort: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
      weekdaysMin: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
      longDateFormat: {
        LT: 'HH:mm',
        L: 'DD/MM/YYYY',
        LL: 'D [de] MMMM [de] YYYY',
        LLL: 'D [de] MMMM [de] YYYY [às] LT',
        LLLL: 'dddd, D [de] MMMM [de] YYYY [às] LT',
      },
      calendar: {
        sameDay: '[Hoje às] LT',
        nextDay: '[Amanhã às] LT',
        nextWeek: 'dddd [às] LT',
        lastDay: '[Ontem às] LT',
        lastWeek() {
          return this.day() === 0 || this.day() === 6
            ? '[Último] dddd [às] LT' // Saturday + Sunday
            : '[Última] dddd [às] LT' // Monday - Friday
        },
        sameElse: 'L',
      },
      relativeTime: {
        future: 'em %s',
        past: '%s atrás',
        s: 'segundos',
        m: 'um minuto',
        mm: '%d minutos',
        h: 'uma hora',
        hh: '%d horas',
        d: 'um dia',
        dd: '%d dias',
        M: 'um mês',
        MM: '%d meses',
        y: 'um ano',
        yy: '%d anos',
      },
      ordinal: '%dº',
    })
  }

  if (localeKey === 'es-ES' || localeKey === 'es') {
    return moment.updateLocale('es', {
      months:
        'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
          '_'
        ),
      monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
      weekdays:
        'Domingo_Lunes_Martes_Mi\u00e9rcoles_Jueves_Viernes_S\u00e1bado'.split(
          '_'
        ),
      weekdaysShort: 'Dom._Lun._Mar._Mi\u00e9._Jue._Vie._S\u00e1b.'.split('_'),
      weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_S\u00e1'.split('_'),
      longDateFormat: {
        LT: 'H:mm',
        LTS: 'H:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D [de] MMMM [de] YYYY',
        LLL: 'D [de] MMMM [de] YYYY H:mm',
        LLLL: 'dddd, D [de] MMMM [de] YYYY H:mm',
      },
      calendar: {
        sameDay: function day() {
          return `[hoy a la ${this.hours() !== 1 ? 's' : ''}] LT`
        },
        nextDay: function nextDay() {
          return `[ma\u00f1ana a la' ${this.hours() !== 1 ? 's' : ''}] LT`
        },
        nextWeek: function nextWeek() {
          return `dddd [a la' ${this.hours() !== 1 ? 's' : ''}] LT`
        },
        lastDay: function lastDay() {
          return `[ayer a la' ${this.hours() !== 1 ? 's' : ''}] LT`
        },
        lastWeek: function lastWeek() {
          return `[el] dddd [pasado a la' ${this.hours() !== 1 ? 's' : ''}] LT`
        },
        sameElse: 'L',
      },
      relativeTime: {
        future: 'en %s',
        past: 'hace %s',
        s: 'unos segundos',
        m: 'un minuto',
        mm: '%d minutos',
        h: 'una hora',
        hh: '%d horas',
        d: 'un d\u00eda',
        dd: '%d d\u00edas',
        M: 'un mes',
        MM: '%d meses',
        y: 'un a\u00f1o',
        yy: '%d a\u00f1os',
      },
      ordinal: '%dº',
    })
  }

  return moment.updateLocale('en')
}

export function LocaleProvider({ children }) {
  const localeKey = useSelector(state => state.locale.key)
  const messages = useMemo(
    () => flattenObject(getMessages(localeKey)),
    [localeKey]
  )

  getMomentLocale(localeKey)
  Highcharts.setOptions({
    lang: {
      months: moment.months(),
      weekdays: moment.weekdays(),
      shortMonths: moment.monthsShort(),
    },
  })
  return (
    <IntlProvider locale={localeKey} messages={messages}>
      {children}
    </IntlProvider>
  )
}

LocaleProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
}

export default LocaleProvider
