import Elasticsearch from 'elasticsearch'

const client = new Elasticsearch.Client({
  host: window._env_?.ES_URL,
})

export default function logMessage(pageName, level, message, user = 'System') {
  if (!client.transport._isCachedConnection) {
    console.warn('Elasticsearch client is not connected. Message not logged.')
    return
  }

  if (!window._env_?.ES_INDEX) {
    console.warn(
      'ES_INDEX environment variable is not defined. Message not logged.'
    )
    return
  }

  client.index({
    index: window._env_?.ES_INDEX,
    body: {
      log: message,
      page_name: pageName,
      level,
      '@timestamp': new Date().toISOString(),
      user,
    },
  })
}
